import React, { useContext, useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSearch,
  faCamera,
  faEnvelope,
  faFile,
  faBox,
  faCheckCircle,
  faExclamationTriangle,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import db, { auth } from '../../backend/firebase/firebaseConfig';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import '../../styles/global.css';
import { LanguageContext } from '../../contexts/LanguageContext';
import { useLocation, useNavigate } from 'react-router-dom';
import NavigationSideBar from '../dashboard/NavigationSideBar';
import QrScanner from 'qr-scanner';
import QrFrame from '../../assets/images/qr-frame.svg';
import CryptoJS from 'crypto-js'; // Import CryptoJS for decryption

const CheckQRCode = () => {
  const { language, translations } = useContext(LanguageContext);
  const t = translations[language].checkQRCode;

  const [qrCodeValue, setQRCodeValue] = useState('');
  const [qrCodeDetails, setQRCodeDetails] = useState(null);
  const [scannerActive, setScannerActive] = useState(false);

  // For visual checks / colors
  const [senderLogo, setSenderLogo] = useState('');
  const [setSenderIdentityColor] = useState('');
  const [checkColor, setCheckColor] = useState('green');
  const [warningColor, setWarningColor] = useState('red');

  const [hasWarnings, setHasWarnings] = useState(false);

  // Report form
  const [showReportForm, setShowReportForm] = useState(false);
  const [reportReason, setReportReason] = useState('');

  const location = useLocation();
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  // Refs for scanner
  const videoEl = useRef(null);
  const qrBoxEl = useRef(null);
  const scanner = useRef(null);

  // Key used for decrypting user input
  const encryptionKey = 'your-encryption-key'; // Replace with the same key used during encryption

  // On mount, check if user is logged in
  useEffect(() => {
    const authInstance = getAuth();
    const unsubscribe = onAuthStateChanged(authInstance, async (user) => {
      setIsLoggedIn(!!user);
    });
    return unsubscribe; // Cleanup subscription on unmount
  }, []);

  // If the path is "/scan", auto-activate the scanner
  useEffect(() => {
    if (location.pathname === '/scan') {
      setScannerActive(true);
    }
  }, [location.pathname]);

  const handleInputChange = (e) => {
    setQRCodeValue(e.target.value);
  };

  // color change of the check symbol, toggles every second
  useEffect(() => {
    if (qrCodeDetails && qrCodeDetails.senderIdentityColor) {
      const intervalId = setInterval(() => {
        setCheckColor((prevColor) =>
          prevColor === 'green' ? qrCodeDetails.senderIdentityColor : 'green'
        );
        setWarningColor((prevColor) => (prevColor === 'red' ? 'blue' : 'red'));
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [qrCodeDetails]);

  // Core function to look up the QR code in Firestore
  const searchQRCode = async (qrCodeId) => {
    // Check in activeQRCodes
    let docRef = doc(db, 'activeQRCodes', qrCodeId);
    let docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      // Found in activeQRCodes
      const data = docSnap.data();
      setQRCodeDetails(data);

      // If there's a sender logo / color
      if (data.senderLogo) setSenderLogo(data.senderLogo);
      if (data.senderIdentityColor) setSenderIdentityColor(data.senderIdentityColor);

      // Increment numberOfScans
      await updateDoc(docRef, {
        numberOfScans: (data.numberOfScans || 0) + 1,
      });

      // Check for warnings on the sender
      const senderUID = data.senderUID;
      let collectionName = '';
      if (senderUID.startsWith('COMDEP')) {
        collectionName = 'companyDepartments';
      } else if (senderUID.startsWith('BRANCHDEP')) {
        collectionName = 'branchDepartments';
      } else if (senderUID.startsWith('BRANCH')) {
        collectionName = 'branches';
      } else if (senderUID.startsWith('COMP')) {
        collectionName = 'companies';
      } else {
        collectionName = 'users';
      }

      docRef = doc(db, collectionName, senderUID);
      docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const warningsField =
          collectionName === 'users'
            ? 'userWarnings'
            : `${collectionName.slice(0, -1)}Warnings`;
        setHasWarnings((docSnap.data()[warningsField] || []).length > 1);
      } else {
        setHasWarnings(false);
      }
    } else {
      // Not found in activeQRCodes => check in inactiveQRCodes
      docRef = doc(db, 'inactiveQRCodes', qrCodeId);
      docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        // It's in the inactive codes
        if (window.confirm(t.doYouWantToActivateThisQRCode)) {
          if (auth.currentUser) {
            await updateDoc(docRef, { IDOfOwner: auth.currentUser.uid });
            navigate(`/activate-qr-code?q=${qrCodeId}`);
          } else {
            sessionStorage.setItem('redirectAfterLogin', `/activate-qr-code?q=${qrCodeId}`);
            navigate('/login');
          }
        }
      } else {
        // Not found anywhere => show "doesn't exist"
        // We'll store a special object in state to show the red X and message
        setQRCodeDetails({ notFound: true });
      }
    }
  };

  // For abuse reporting
  const handleReportClick = () => setShowReportForm(true);
  const handleCloseReportForm = () => setShowReportForm(false);
  const handleReportReasonChange = (e) => setReportReason(e.target.value);

  const handleReportSubmit = async (e) => {
    e.preventDefault();

    const authInstance = getAuth();
    const user = authInstance.currentUser;
    const reporterUID = user ? user.uid : 'Anonymous';

    if (!qrCodeDetails) return; // Edge case

    const reportEntry = {
      reason: reportReason,
      timestamp: new Date().toISOString(),
      reporterUID,
      reportedqrCodeValue: qrCodeValue,
    };

    const { senderUID } = qrCodeDetails;

    // figure out the collection for the sender
    let collectionName = '';
    if (senderUID.startsWith('COMDEP')) {
      collectionName = 'companyDepartments';
    } else if (senderUID.startsWith('BRANCHDEP')) {
      collectionName = 'branchDepartments';
    } else if (senderUID.startsWith('BRANCH')) {
      collectionName = 'branches';
    } else if (senderUID.startsWith('COMP')) {
      collectionName = 'companies';
    } else {
      collectionName = 'users';
    }

    // Add the report to the sender doc
    const senderDocRef = doc(db, collectionName, senderUID);
    const senderDocSnap = await getDoc(senderDocRef);

    if (senderDocSnap.exists()) {
      const warningsField =
        collectionName === 'users'
          ? 'userWarnings'
          : `${collectionName.slice(0, -1)}Warnings`;
      const currentWarnings = senderDocSnap.data()[warningsField] || [];
      await updateDoc(senderDocRef, {
        [warningsField]: [...currentWarnings, reportEntry],
      });
    } else {
      console.error('Sender document not found:', senderUID);
    }

    // Also add the report to the QR code doc
    const qrCodeDocRef = doc(db, 'activeQRCodes', qrCodeValue);
    const qrCodeDocSnap = await getDoc(qrCodeDocRef);

    if (qrCodeDocSnap.exists()) {
      const qrCodeWarnings = qrCodeDocSnap.data().qrCodeWarnings || [];
      await updateDoc(qrCodeDocRef, {
        qrCodeWarnings: [...qrCodeWarnings, reportEntry],
      });
    } else {
      // might be in inactive
      const inactiveQrCodeDocRef = doc(db, 'inactiveQRCodes', qrCodeValue);
      const inactiveQrCodeDocSnap = await getDoc(inactiveQrCodeDocRef);

      if (inactiveQrCodeDocSnap.exists()) {
        const qrCodeWarnings = inactiveQrCodeDocSnap.data().qrCodeWarnings || [];
        await updateDoc(inactiveQrCodeDocRef, {
          qrCodeWarnings: [...qrCodeWarnings, reportEntry],
        });
      } else {
        console.error(
          'QR code document not found in both active and inactive collections:',
          qrCodeValue
        );
      }
    }

    handleCloseReportForm();
  };

  // Form submission => parse user input, maybe decrypt => search
  const handleSubmit = async (e) => {
    e.preventDefault();
    let qrCodeId = qrCodeValue.trim();
    // Attempt to decrypt
    let decryptedCode = '';
    try {
      decryptedCode = CryptoJS.AES.decrypt(qrCodeId, encryptionKey).toString(
        CryptoJS.enc.Utf8
      );
    } catch (e) {
      // Decryption fail => do nothing
    }
    if (decryptedCode) {
      qrCodeId = decryptedCode;
    }
    searchQRCode(qrCodeId);
  };

  // QrScanner success callback
  const handleScanSuccess = (result) => {
    const data = result.data;
    // 1) Try parse as URL
    try {
      const url = new URL(data);
      const paramCode = url.searchParams.get('q');
      if (paramCode) {
        // It's a normal QR code
        setQRCodeValue(paramCode);
        searchQRCode(paramCode);
        setScannerActive(false);
        return;
      }
    } catch (e) {
      // Not a URL
    }

    // 2) Try decrypt
    try {
      const decryptedCode = CryptoJS.AES.decrypt(data, encryptionKey).toString(
        CryptoJS.enc.Utf8
      );
      if (decryptedCode) {
        setQRCodeValue(decryptedCode);
        searchQRCode(decryptedCode);
        setScannerActive(false);
      } else {
        // Instead of alert, show invalid QR code
        setQRCodeDetails({ invalidFormat: true });
        setScannerActive(false);
      }
    } catch (e) {
      // Also show invalid QR code
      setQRCodeDetails({ invalidFormat: true });
      setScannerActive(false);
    }
  };

  const handleScanFail = (error) => {
    console.error(error);
  };

  // Toggle camera scanning
  const toggleScanner = () => {
    setScannerActive(!scannerActive);
  };

  // Setup or teardown QrScanner instance
  useEffect(() => {
    if (scannerActive && videoEl.current && !scanner.current) {
      scanner.current = new QrScanner(videoEl.current, handleScanSuccess, {
        onDecodeError: handleScanFail,
        preferredCamera: 'environment',
        highlightScanRegion: true,
        highlightCodeOutline: true,
        overlay: qrBoxEl.current,
      });
      scanner.current.start().catch((err) => console.error(err));
    } else if (!scannerActive && scanner.current) {
      scanner.current.stop();
      scanner.current = null;
    }
  }, [scannerActive]);

  // If there's a "q" param in the URL, handle that
  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const qrCodeIdFromURL = query.get('q');
    if (qrCodeIdFromURL) {
      setQRCodeValue(qrCodeIdFromURL);
      searchQRCode(qrCodeIdFromURL);
    }
  }, [location]);

  return (
    <div className="white-page-content">
      <div className="check-qr-code-page">
        <div>
          {isLoggedIn && <NavigationSideBar />}

          <h1>{t.title}</h1>
          <p>{t.description}</p>

          <form onSubmit={handleSubmit} className="qr-code-form">
            <div className="input-group">
              <input
                type="text"
                value={qrCodeValue}
                onChange={handleInputChange}
                placeholder={t.placeholder}
                required
              />
              <button type="submit" className="button-search">
                <FontAwesomeIcon icon={faSearch} />
              </button>
              <button
                type="button"
                className="button-camera"
                onClick={toggleScanner}
              >
                <FontAwesomeIcon icon={faCamera} />
              </button>
            </div>
          </form>

          {scannerActive && (
            <div className="qr-reader">
              <video ref={videoEl}></video>
              <div ref={qrBoxEl} className="qr-box">
                <img
                  src={QrFrame}
                  alt="Qr Frame"
                  width={256}
                  height={256}
                  className="qr-frame"
                />
              </div>
            </div>
          )}

          {/* Show details if we have them (and not invalid/notFound) */}
          {qrCodeDetails && !qrCodeDetails.notFound && !qrCodeDetails.invalidFormat && (
            <div className="qr-code-details">
              <div className="verified-icon">
                {senderLogo ? (
                  <>
                    <img src={senderLogo} alt="Sender Logo" />
                    <div
                      className="check-symbol"
                      style={{ color: hasWarnings ? warningColor : checkColor }}
                    >
                      <FontAwesomeIcon
                        icon={hasWarnings ? faExclamationTriangle : faCheckCircle}
                      />
                    </div>
                  </>
                ) : (
                  <div
                    className="check-symbol"
                    style={{ color: hasWarnings ? warningColor : checkColor }}
                  >
                    <FontAwesomeIcon
                      icon={hasWarnings ? faExclamationTriangle : faCheckCircle}
                    />
                  </div>
                )}
              </div>

              <div
                className="details-grid"
                style={{ border: `4px dashed ${qrCodeDetails.senderIdentityColor}` }}
              >
                {/* Product Info */}
                <div className="section-label">{t.productInformation}</div>
                <div className="details-item">
                  <span>{t.qrCodeProduct}: </span>
                  {qrCodeDetails.qrCodeProduct === 'DOCUMENT' && (
                    <>
                      {t.document} <FontAwesomeIcon icon={faFile} />
                    </>
                  )}
                  {qrCodeDetails.qrCodeProduct === 'ENVELOPE' && (
                    <>
                      {t.envelope} <FontAwesomeIcon icon={faEnvelope} />
                    </>
                  )}
                  {qrCodeDetails.qrCodeProduct === 'PARCEL' && (
                    <>
                      {t.parcel} <FontAwesomeIcon icon={faBox} />
                    </>
                  )}
                </div>
                <div className="details-item">
                  <span>{t.qrCodeProductSize}:</span>{' '}
                  {qrCodeDetails.qrCodeProductSize}
                </div>

                {/* Sender Info */}
                <div className="section-label">{t.senderInformation}</div>
                <div className="details-item">
                  <span>{t.senderName}:</span> {qrCodeDetails.senderName}
                </div>
                <div className="details-item">
                  <span>{t.senderStreet}:</span> {qrCodeDetails.senderStreet}
                </div>
                <div className="details-item">
                  <span>{t.senderHouseNumber}:</span>{' '}
                  {qrCodeDetails.senderHouseNumber}
                </div>
                <div className="details-item">
                  <span>{t.senderPostalCode}:</span>{' '}
                  {qrCodeDetails.senderPostalCode}
                </div>
                <div className="details-item">
                  <span>{t.senderCity}:</span> {qrCodeDetails.senderCity}
                </div>
                <div className="details-item">
                  <span>{t.senderState}:</span> {qrCodeDetails.senderState}
                </div>
                <div className="details-item">
                  <span>{t.senderCountry}:</span> {qrCodeDetails.senderCountry}
                </div>

                {/* Recipient Info */}
                <div className="section-label">{t.recipientInformation}</div>
                <div className="details-item">
                  <span>{t.recipientName}:</span> {qrCodeDetails.recipientName}
                </div>
                <div className="details-item">
                  <span>{t.recipientStreet}:</span>{' '}
                  {qrCodeDetails.recipientStreet}
                </div>
                <div className="details-item">
                  <span>{t.recipientHouseNumber}:</span>{' '}
                  {qrCodeDetails.recipientHouseNumber}
                </div>
                <div className="details-item">
                  <span>{t.recipientPostalCode}:</span>{' '}
                  {qrCodeDetails.recipientPostalCode}
                </div>
                <div className="details-item">
                  <span>{t.recipientCity}:</span> {qrCodeDetails.recipientCity}
                </div>
                <div className="details-item">
                  <span>{t.recipientState}:</span> {qrCodeDetails.recipientState}
                </div>
                <div className="details-item">
                  <span>{t.recipientCountry}:</span>{' '}
                  {qrCodeDetails.recipientCountry}
                </div>
                <div className="details-item">
                  <span>{t.recipientPhoneNumber}:</span>{' '}
                  {qrCodeDetails.recipientPhoneNumber}
                </div>
                <div className="details-item">
                  <span>{t.recipientLogiHashID}:</span>{' '}
                  {qrCodeDetails.recipientLogiHashID}
                </div>
              </div>

              {/* Report Abuse Button */}
              <div>
                <button onClick={handleReportClick} className="button-report">
                  <box-icon type="solid" color="white" name="report"></box-icon>
                  <p>{t.reportAbuse}</p>
                </button>
              </div>
            </div>
          )}

          {/* If the code does not exist => show Red X + message */}
          {qrCodeDetails && qrCodeDetails.notFound && (
            <div className="qr-code-details">
              <div className="verified-icon" style={{ textAlign: 'center' }}>
                <div
                  className="check-symbol"
                  style={{ color: 'red', fontSize: '4rem' }}
                >
                  <FontAwesomeIcon icon={faTimesCircle} />
                </div>
                <p style={{ color: 'red', marginTop: '0.5rem' }}>
                  {t.QRCodeDoesnotExist}
                </p>
              </div>
            </div>
          )}

          {/* If invalid format => show big X + message */}
          {qrCodeDetails && qrCodeDetails.invalidFormat && (
            <div className="qr-code-details">
              <div className="verified-icon" style={{ textAlign: 'center' }}>
                <div
                  className="check-symbol"
                  style={{ color: 'red', fontSize: '4rem' }}
                >
                  <FontAwesomeIcon icon={faTimesCircle} />
                </div>
                <p style={{ color: 'red', marginTop: '0.5rem' }}>
                  {t.invalidQRCodeFormat}
                </p>
              </div>
            </div>
          )}

          {/* Popup report form */}
          {showReportForm && (
            <form onSubmit={handleReportSubmit} className="popup-report">
              <h1>{t.reportAbuse}</h1>
              <div>
                <button
                  type="button"
                  onClick={handleCloseReportForm}
                  className="button-exit-popup-report"
                >
                  X
                </button>
              </div>
              <label htmlFor="reportReason">{t.selectReason}: </label>
              <select
                id="reportReason"
                value={reportReason}
                onChange={handleReportReasonChange}
                className="report-select-popup-report"
                required
              >
                <option value="" disabled>
                  {t.selectReason}
                </option>
                <option value="threateningMessage">{t.threateningMessage}</option>
                <option value="fakeContent">{t.fakeContent}</option>
                <option value="dangerousContent">{t.dangerousContent}</option>
                <option value="identityTheft">{t.identityTheft}</option>
              </select>
              <button type="submit" className="button-submit-popup-report">
                {t.submit}
              </button>
            </form>
          )}

          <div className="mobile-app-note-container">
            <p className="mobile-app-note">{t.mobileAppNote}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckQRCode;
