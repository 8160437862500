// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage'; // Import Firebase Storage

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBcJywBnOtILdHFGHjuYjLhusb9qgLppd0",
  authDomain: "logihash-1f239.firebaseapp.com",
  databaseURL: "https://logihash-1f239-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "logihash-1f239",
  storageBucket: "logihash-1f239.appspot.com",
  messagingSenderId: "1007306709578",
  appId: "1:1007306709578:web:68b3cef2009dad5629e4b3",
  measurementId: "G-E2WSG5T694"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export const auth = getAuth(app);
export const storage = getStorage(app); // Initialize and export Firebase Storage

export default db;