import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { LanguageContext } from '../../contexts/LanguageContext';
import { collection, query, where, getDocs, getDoc, doc } from 'firebase/firestore';
import db, { auth } from '../../backend/firebase/firebaseConfig';
import { onAuthStateChanged } from 'firebase/auth';
import QRCode from 'qrcode.react';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile, faEnvelope, faBox } from '@fortawesome/free-solid-svg-icons';
import TrustecValleyLogo from '../../assets/images/Trustec_Valley_Logo.png';
import '../../styles/global.css';

import CryptoJS from 'crypto-js'; // Import CryptoJS for encryption
import TImage from '../../assets/images/TImage-black.png'; // Import the 'T' image

const QRCodesToBeUsed = () => {
  const { language, translations } = useContext(LanguageContext);
  const t = translations[language].dashboard;
  const [inactiveQRCodes, setInactiveQRCodes] = useState([]);

  const [isAdmin, setIsAdmin] = useState(false); // New state for admin check
  const [isLoggedIn, setIsLoggedIn] = useState(false); // State for login check

  const encryptionKey = 'your-encryption-key'; // Replace with your actual encryption key

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setIsLoggedIn(!!user);
      if (user) {
        const userRef = doc(db, 'users', user.uid);
        const docSnap = await getDoc(userRef);
        if (docSnap.exists()) {
          setIsAdmin(docSnap.data().admin || false); // Check if user is admin
        }
      }
    });
    return unsubscribe; // Cleanup subscription on unmount
  }, []);

  useEffect(() => {
    const fetchInactiveQRCodes = async () => {
      const userUID = auth.currentUser.uid;
      const inactiveQRCodesRef = collection(db, 'inactiveQRCodes');

      // First query to check if IDOfOwner is a string
      const queryString = query(inactiveQRCodesRef, where('IDOfOwner', '==', userUID));
      const querySnapshotString = await getDocs(queryString);

      // Filter documents where IDOfOwner is a string and new is true
      const qrCodesString = querySnapshotString.docs
        .map((doc) => {
          const data = doc.data();
          return data.IDOfOwner === userUID && data.new === true ? { id: doc.id, ...data } : null;
        })
        .filter(Boolean);

      // Second query to check if IDOfOwner is an array
      const queryArray = query(inactiveQRCodesRef, where('IDOfOwner', 'array-contains', userUID));
      const querySnapshotArray = await getDocs(queryArray);

      // Filter documents where IDOfOwner includes userUID and new is true
      const qrCodesArray = querySnapshotArray.docs
        .map((doc) => {
          const data = doc.data();
          return data.IDOfOwner.includes(userUID) && data.new === true ? { id: doc.id, ...data } : null;
        })
        .filter(Boolean);

      // Combine results from both queries
      const combinedQRCodes = [...qrCodesString, ...qrCodesArray];

      setInactiveQRCodes(combinedQRCodes);
    };

    fetchInactiveQRCodes();
  }, []);

  const navigate = useNavigate();

  const activateQRCode = (qrCodeUID) => {
    navigate(`/activate-qr-code?q=${qrCodeUID}`);
  };

  const printQRCodepdf = (qrCodeUID) => {
    const input = document.getElementById(`qrCodeToPrint-${qrCodeUID}`);
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF();
      pdf.addImage(imgData, 'PNG', 0, 0);
      pdf.save(`LogiHash-${qrCodeUID}.pdf`);
    });
  };

  const printQRCodepng = (qrCodeUID) => {
    const input = document.getElementById(`qrCodeToPrint-${qrCodeUID}`);
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');

      // Create a link element
      const link = document.createElement('a');
      link.href = imgData;
      link.download = `LogiHash-${qrCodeUID}.png`;

      // Append the link to the body
      document.body.appendChild(link);

      // Trigger the download
      link.click();

      // Remove the link from the document
      document.body.removeChild(link);
    });
  };

  const printEnvelopeQRCode = async (qrCodeUID) => {
    // Fetch the QR code data from Firestore
    const qrCodeRef = doc(db, 'inactiveQRCodes', qrCodeUID);
    const qrCodeDoc = await getDoc(qrCodeRef);

    // Determine the text to use for the scan instruction
    const scanText =
      qrCodeDoc.exists() && qrCodeDoc.data().scanText
        ? t[qrCodeDoc.data().scanText] || t.scanTextOption1
        : t.scanTextOption1;

    const input = document.getElementById(`qrCodeToPrint-${qrCodeUID}`);
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');

      // Create a PDF with envelope size (110 mm x 220 mm)
      const pdf = new jsPDF({
        orientation: 'landscape',
        unit: 'mm',
        format: [110, 220],
      });

      // Add the captured QR code image to the top left of the PDF
      pdf.addImage(imgData, 'PNG', 60, 20, 30, 25);

      // Add the text below the QR code
      pdf.setFontSize(8);
      pdf.text(scanText, 60, 50);

      // Add TrustecValley logo
      const logo = new Image();
      logo.src = TrustecValleyLogo;
      logo.onload = () => {
        const logoWidth = 40; // Set the logo width
        const logoHeight = (logoWidth / logo.width) * logo.height; // Maintain aspect ratio
        pdf.addImage(logo, 'PNG', 135, 20, logoWidth, logoHeight); // Adjust the position and size as needed

        // Add text content to the PDF
        const text = `
            ${t.envelopeLine1} 
            ${t.envelopeLine2} 
            ${t.envelopeLine3}
    
            ${t.trustecvalleycontact}:
            ${t.trustecvalleyemail}: info@trustecvalley.com
            ${t.trustecvalleywebsite}: www.trustecvalley.com`;

        // Set font size
        pdf.setFontSize(10); // Adjust the font size as needed
        pdf.text(text, 90, 60); // Adjust the position as needed

        // Save the PDF
        pdf.save(`Envelope-LogiHash-${qrCodeUID}.pdf`);
      };
    });
  };

  const renderInactiveQRCodeList = () => {
    if (inactiveQRCodes.length > 0) {
      return (
        <div className="table-main-container">
          <h2>{t.inactivecodesheader}</h2>
          <table className="table-container">
            <thead>
              <tr>
                <th>{t.status}</th>
                <th>{t.QRCode}</th>
                <th>{t.type}</th> {/* New column for Type */}
                <th>{t.qrCodeProduct}</th>
                <th>{t.qrCodeProductSize}</th>
                <th>{t.QRCodeUID}</th>
                <th>{t.lastChangeTimestamp}</th>
                <th>{t.logiHashIDofOwner}</th>
                <th>{t.activateQRCode}</th>
                {isAdmin && <th>{t.printQRCode} PDF</th>}
                {isAdmin && <th>{t.printQRCode} PNG</th>}
                {isAdmin && <th>{t.printEnvelope}</th>}
              </tr>
            </thead>
            <tbody>
              {inactiveQRCodes.map((qrCode) => {
                const codeType = qrCode.codeType || 'QRCode'; // Default to 'QRCode' if codeType doesn't exist

                // Determine QR code value and image settings
                let qrCodeValue;
                let qrCodeImageSettings = null;

                if (codeType === 'TCode') {
                  // Encrypt the QR code UID
                  const encryptedCode = CryptoJS.AES.encrypt(qrCode.qrCodeUID, encryptionKey).toString();
                  qrCodeValue = encryptedCode;
                  qrCodeImageSettings = {
                    src: TImage,
                    height: 15,
                    width: 15,
                    excavate: true,
                  };
                } else {
                  qrCodeValue = `https://logihash.trustecvalley.com/check-qr-code?q=${qrCode.qrCodeUID}`;
                }

                return (
                  <tr key={qrCode.id}>
                    <td>
                      {qrCode.active ? (
                        <span className="active-circle"></span>
                      ) : (
                        <span className="inactive-circle" title={t.inactive}></span>
                      )}
                    </td>
                    <td>
                      <div id={`qrCodeToPrint-${qrCode.qrCodeUID}`}>
                        <QRCode
                          value={qrCodeValue}
                          size={70}
                          level="H"
                          imageSettings={qrCodeImageSettings}
                        />
                        <p>{qrCode.qrCodeUID}</p>
                      </div>
                    </td>
                    <td>{codeType}</td> {/* Display the code type */}
                    <td>
                    {qrCode.qrCodeProduct === 'DOCUMENT' && (
                        <>
                          {t.document} <FontAwesomeIcon icon={faFile} />
                        </>
                      )}
                      {qrCode.qrCodeProduct === 'ENVELOPE' && (
                        <>
                          {t.envelope} <FontAwesomeIcon icon={faEnvelope} />
                        </>
                      )}
                      {qrCode.qrCodeProduct === 'PARCEL' && (
                        <>
                          {t.parcel} <FontAwesomeIcon icon={faBox} />
                        </>
                      )}
                    </td>
                    <td>{qrCode.qrCodeProductSize}</td>
                    <td>{qrCode.qrCodeUID}</td>
                    <td>{qrCode.lastChangeTimestamp?.toDate().toLocaleString()}</td>
                    <td>{qrCode.IDOfOwner}</td>
                    <td>
                      <button className="button-activate" onClick={() => activateQRCode(qrCode.qrCodeUID)}>
                        {t.activateQRCode}
                      </button>
                    </td>
                    {isAdmin && (
                      <td>
                        <button className="button-print" onClick={() => printQRCodepdf(qrCode.qrCodeUID)}>
                          {t.printQRCode} PDF
                        </button>
                      </td>
                    )}
                    {isAdmin && (
                      <td>
                        <button className="button-print" onClick={() => printQRCodepng(qrCode.qrCodeUID)}>
                          {t.printQRCode} PNG
                        </button>
                      </td>
                    )}
                    {isAdmin && (
                      <td>
                        <button className="button-print" onClick={() => printEnvelopeQRCode(qrCode.qrCodeUID)}>
                          {t.printEnvelope}
                        </button>
                      </td>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      );
    } else {
      return (
        <div className="table-main-container">
          <p>{t.emptyList}</p>
        </div>
      );
    }
  };

  return renderInactiveQRCodeList();
};

export default QRCodesToBeUsed;
