import React, { useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import '../../styles/global.css';
import db, { auth } from '../../backend/firebase/firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';

import IdentityVerificationPicture from '../../assets/images/identity_verification_picture.png';

const AccountVerification = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const checkIdentityVerification = async () => {
      const user = auth.currentUser;
      if (user) {
        const userRef = doc(db, "users", user.uid);
        const docSnap = await getDoc(userRef);

        if (docSnap.exists() && docSnap.data().identityVerified === true) {
          navigate('/dashboard');
        }
      }
    };

    checkIdentityVerification();
  }, [navigate]);



  return (
    <div className="white-page-content">
    <div className="account-verification-page">
      <h1>Account Verification</h1>
      <p>This is only a prototype where we manually check the identity to make the prototype testable. In the official version, this step will be done via offical documents.</p>
      
      <div className="desktop-only-container">
        <img src={IdentityVerificationPicture} alt="Verification Picture" className="logihash-desktop-user" />
        </div>
      
      {/*<button onClick={handleSkipVerification} className='button-submit'>
        Skip Identity Verification
      </button>*/}
    </div>
    </div>
  );
};

export default AccountVerification;
